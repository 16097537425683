import React from "react"
import { Headline, Text } from "../../common/headline"
import { Container, Content } from "../agb/index"
import { useIntl } from "gatsby-plugin-react-intl"
import i18n from "./i18n"

const ReturnForm = () => {
  const intl = useIntl()
  return (
    <Container>
      <Content>
        <Headline type="h3">{intl.formatMessage(i18n.headline)}</Headline>
        <Headline type="h4">{intl.formatMessage(i18n.righttitle)}</Headline>
        <Text>
          {intl.formatMessage(i18n.righttext, {
            break: str => (
              <span>
                <br /> <br />
                {str}{" "}
              </span>
            ),
          })}{" "}
        </Text>
        <Headline type="h4" margin="2rem 0">
          {intl.formatMessage(i18n.processtitle)}
        </Headline>
        <Text>
          {intl.formatMessage(i18n.processtext, {
            break: str => (
              <span>
                <br /> <br />
                {str}{" "}
              </span>
            ),
          })}{" "}
        </Text>

        <Headline type="h3" margin="2rem 0">
          {intl.formatMessage(i18n.contractheader)}
        </Headline>
        <Headline type="h4">{intl.formatMessage(i18n.contracttitle)}</Headline>
        <Text>
          {intl.formatMessage(i18n.contracttext, {
            break: str => (
              <span>
                <br /> <br />
                {str}{" "}
              </span>
            ),
          })}{" "}
        </Text>

        <Headline type="h4" margin="2rem 0">
          {intl.formatMessage(i18n.contractProcesstitle)}
        </Headline>
        <Text>
          {intl.formatMessage(i18n.contractProcesstext, {
            break: str => (
              <span>
                <br /> <br />
                {str}{" "}
              </span>
            ),
          })}{" "}
        </Text>

        <Headline type="h3" margin="2rem 0">
          {intl.formatMessage(i18n.prototypetitle)}
        </Headline>
        <Text>
          {intl.formatMessage(i18n.prototypetext, {
            break: str => (
              <span>
                <br /> <br />
                {str}{" "}
              </span>
            ),
          })}{" "}
        </Text>
      </Content>
    </Container>
  )
}

export default ReturnForm
