import React from "react"
import Page from "../components/common/page"


import { ContentRow } from "../components/common/shared-components/layouts"
import ReturnForm from "../components/page-components/return"

const CONTENT_ROWS = [[<ReturnForm/>]]

const Return = () => {
  return (
    <Page title="board">
      {CONTENT_ROWS.map((components, key) => (
        <ContentRow key={key} components={components} />
      ))}
    </Page>
  )
}

export default Return
